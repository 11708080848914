const Header = () => {
    return (
    <div class="navbar navbar-dark bg-dark shadow-sm">
      <div class="container">
        <a href="#" class="navbar-brand d-flex align-items-center">
          <strong>Binance Volume Monitor</strong>
        </a>
      </div>
    </div>
    )
}

export default Header
